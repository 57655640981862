import React, { useState } from "react";
import BookingTableHeader from "../../../utils/bookingTable/BookingTableHeader";
import FlightBookingTableRow from "../../../utils/bookingTable/BookingTableRow-Flight";
import PackageBookingTableRow from "../../../utils/bookingTable/BookingTableRow-Package";
import { Link } from "react-router-dom";
import { headers } from "../../../utils/bookingTable/BookingHeaders";

export default function BookingTables({
  type,
  filteredBookings,
  page,
  itemsPerPage,
  bookingStatus,
  bookingStatusKeys,
  mutateEstadoReserva,
  openModal,
  mutateEstadoReservaPaquete,
  translateCurrency,
  path,
  selectedRows,
  setSelectedRows
}) {
 
  const handleRowSelect = (traveler) => {
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.some((row) => row.id === traveler.id);
      return isSelected
        ? prevSelected.filter((row) => row.id !== traveler.id)
        : [...prevSelected, traveler];
    });
  };

  return (
    <table className="w-full border-collapse  rounded-lg overflow-hidden bg-lightBlue2 ">
      <BookingTableHeader
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        data={filteredBookings}
        headers={headers(type)}
      />

      {filteredBookings?.length > 0 ? (
        filteredBookings
          ?.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
          ?.map((v, index) =>
            type === "flight" ? (
              <FlightBookingTableRow
                key={v.id}
                index={index}
                v={v}
                type={type}
                bookingStatus={bookingStatus}
                bookingStatusKeys={bookingStatusKeys}
                mutateEstadoReserva={mutateEstadoReserva}
                openModal={openModal}
              />
            ) : type === "package" ? (
              <PackageBookingTableRow
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleRowSelect={handleRowSelect}
                key={v.id}
                index={index}
                v={v}
                type={type}
                bookingStatus={bookingStatus}
                bookingStatusKeys={bookingStatusKeys}
                mutateEstadoReserva={mutateEstadoReservaPaquete}
                openModal={openModal}
                currency={translateCurrency(v?.Packages?.[0]?.currency_type_id)}
              />
            ) : type === "hotel" ? (
              <></>
            ) : null
          )
      ) : (
        <tr>
          <td colSpan="7" className="my-3 ml-8 text-red-500 ">
            No se encontraron reservas en "
            {type === "flight"
              ? "Vuelos"
              : type === "package"
              ? "Paquetes"
              : "Hoteles"}
            ".
            <Link
              to={`${path}${
                type === "flight"
                  ? "package"
                  : type === "package"
                  ? "hotel"
                  : "flight"
              }`}
              className="underline pl-4 text-black"
            >
              Ver en "
              {type === "flight"
                ? "Paquetes"
                : type === "package"
                ? "Hoteles"
                : "Vuelos"}
              "
            </Link>
          </td>
        </tr>
      )}
    </table>
  );
}
