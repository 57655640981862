import React from 'react'
import { useLocationQuery } from '../../hooks/useLocationQuery';
import FlightsBookings from '../flights/booking/FlightsBookings';
import PackagesBooking from '../packages/booking/PackagesBooking';
import HotelsBooking from '../hotels/hotelsBookings/HotelsBooking';

export default function Detail() {
    const { parsedHash } = useLocationQuery();
    const type = parsedHash?.type;
  return (
    <>
            {type === "flights" && <FlightsBookings />}
            {type === "packages" && <PackagesBooking />}
            {type === "hotels" && <HotelsBooking />}
            {!type && <p>Please select a booking type (flights, packages, or hotels).</p>}
        </>
  )
}
