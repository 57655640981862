import React from "react";
import TableQuoteRow from "./quoteRow/TableQuoteRow";

export default function TableQuoteBody({
  quotes,
  selectedRows,
  handleRowSelect,
}) {

  return (
    <tbody className="text-sm xl:text-base items-center text-gray">
      {quotes.length > 0 ? (
        quotes.map((quote, index) => (
          <React.Fragment key={index}>
            <TableQuoteRow
              index={index}
              qto={quote}
              selectedRows={selectedRows}
              handleRowSelect={handleRowSelect}
            />
          </React.Fragment>
        ))
      ) : (
        <tr>
          <td colSpan="100%" className="text-center">
            No hay cotizaciones disponibles.
          </td>
        </tr>
      )}
    </tbody>
  );
}
