import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import PackageDetailFeeSection from "../../packageDetail/detailSections/tourSection/feeSection/PackageDetailFeeSection";
import { useFees } from "../../../../hooks/useFees";

export default function PackageLinkTourSection({
  tours,
  setSelectedTour,
  setSelectedFee,
}) {
  const [selectedFeeId, setSelectedFeeId] = useState("");
  const { getFeesForLink } = useFees();
  const [allFees, setAllFees] = useState([]);

  useEffect(() => {
    if (tours && tours.length > 0) {
      const fetchFeesForTours = async () => {
        const feesPromises = tours.map((tour) => getFeesForLink(tour.id));
        const feesResults = await Promise.all(feesPromises);
        console.log("Fees Results:", feesResults); // Debug fees
        setAllFees(feesResults.flat());
      };
  
      fetchFeesForTours();
    }
  }, [tours]);
  

  return (
    <div className="overflow-x-auto p-6 shadow-lg rounded-lg bg-white mt-4">
      <table
        className={`w-full bg-white text-center hover:bg-lightBlue2 rounded-lg border-none`}
      >
        <thead>
          <tr className="bg-lightBlue">
            <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
              Salida
            </th>
            <th className="border-2 border-lightBlue font-medium text-brightBlue text-center">
              Regreso
            </th>
            <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
              Hotel
            </th>
            <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">
              Categoria
            </th>
          </tr>
        </thead>
        <tbody>
          {tours.map((tour, tourKey) => (
            <React.Fragment key={tourKey}>
              {/* Tour Row */}
              <tr className="bg-white text-center border-2 border-lightBlue">
                <td className="p-2 border-2 border-lightBlue">
                  {tour.dateIn
                    ? format(new Date(tour.dateIn), "dd/MM/yy")
                    : "fecha salida"}
                </td>
                <td className="p-2 border-2 border-lightBlue">
                  {tour.dateIn
                    ? format(new Date(tour.dateOut), "dd/MM/yy")
                    : "fecha salida"}
                </td>
                <td className="p-2 border-2 border-lightBlue">
                  {tour.hotel ? tour.hotel : "-"}
                </td>
                <td className="p-2 border-2 border-lightBlue">
                  {tour.catergory ? tour.catergory : "-"}
                </td>
              </tr>

              {allFees.length > 0 && // Ensure `allFees` is loaded
                allFees
                  .filter((fee) => fee.id_tours === tour.id) // Match fee to the corresponding tour
                  .map((filteredFee, feeKey) => (
                    <PackageDetailFeeSection
                      tour={tour}
                      key={feeKey}
                      filteredFee={filteredFee}
                      selectedFeeId={selectedFeeId}
                      setSelectedFeeId={setSelectedFeeId}
                      setSelectedTour={setSelectedTour}
                      setSelectedFee={setSelectedFee}
                    />
                  ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
