import React from "react";
import SEO from "../../../utils/SEO";
import Head from "../../head";
import PackageLink from "./PackageLink";


export default function PackageLinkAdmin() {
  return (
    <div>
      <SEO title={"Paquetes"} />
      <Head />
      <div className="flex justify-center">
        <PackageLink />
      </div>
    </div>
  );
}
