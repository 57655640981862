import { useCallback, useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { saveTourOperator } from "../utils/saveTourOperator";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";
import { isAfter, parseISO } from "date-fns";
import { useParams } from "react-router-dom";

export const usePackages = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { tour_op_id } = useParams(); //
  const [loading, setLoading] = useState(false);
  const [individualPackage, setPackage] = useState([]);
  const [packages, setPackages] = useState([]);
  const [packagesCategories, setPackagesCategories] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  // const querys = QueryString.stringify(
  //   _.omitBy({ ...parsedHash }, (a) => a !== 0 && !a)
  // ).replace(/\s/g, "");

  const createPackage = async (pack) => {
    const response = await axios.post(BASE_URL + "v2/packages", pack, headers);
    return response;
  };

  const updatePackage = async (pack) => {
    const { id, fechaCreacion, ...restOfPackage } = pack;
    return await axios.patch(
      BASE_URL + `v2/packages/${id}`,
      {
        package: { ...restOfPackage },
      },
      headers
    );
  };

  const deletePackage = async (id) => {
    return await axios.delete(BASE_URL + `v2/packages/${id}`, headers);
  };

  const getPackages = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}v2/packages/${tourOP.id}`);
      setPackages(response.data.response);
    } catch (error) {
      console.error(error); // Handle error
      if (error.response && error.response.status === 404) {
        setPackages([]);
      }
    } finally {
      setLoading(false);
    }
  }, [tourOP.id, BASE_URL]);

  const getPackagesByDate = async () => {
    setLoading(true);
    const currentDate = new Date(); // Get the current date and time

    axios
      .get(`${BASE_URL}v2/packages/${tourOP.id}`)
      .then((e) => {
        const filteredPackages = e.data.response.filter((pkg) => {
          const departureDate = parseISO(pkg.departure_date); // Convert departure_date to Date object using parseISO
          return isAfter(departureDate, currentDate); // Compare dates
        });
        setPackages(filteredPackages);
      })
      // .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getPackagesStep2 = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}v2/packages/home/${tourOP.id}`);
      setPackages(response.data.response);
    } catch (error) {
      console.error(error); // Handle error
      if (error.response && error.response.status === 404) {
        setPackages([]);
      }
    } finally {
      setLoading(false);
    }
  }, [tourOP.id, BASE_URL]);
  
  const getPackage = async (package_id) => {
    setLoading(true);

    axios
      .get(BASE_URL + "v2/packages/detail/" + package_id)
      .then((e) => {
        setPackage(e.data.response);
      })
      // .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getPackagesCategories = useCallback(async () => {
    try {
      const response = await axios.get(
        BASE_URL + "v2/packages/categories",
        headers
      );
      setPackagesCategories(response.data.response);
    } catch (error) {
      console.error(error);
    }
  }, [headers,BASE_URL]); // Add dependencies like headers if they change

  // Fetch package categories when the component mounts
  useEffect(() => {
    getPackagesCategories();
  }, []); // Only re-run if getPackagesCategories changes


  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get(BASE_URL + "packages?" + querys)
  //     .then((e) => {
  //       setPackages(e.data);
  //       setLoading(false);
  //     })
  //     .catch(() => setLoading(false));
  // }, [querys]);
  return {
    individualPackage,
    packages,
    loading,
    packagesCategories,
    getPackage,
    getPackages,
    createPackage,
    updatePackage,
    deletePackage,
    getPackagesCategories,
    getPackagesByDate,
    getPackagesStep2
  };
};
