import React from "react";
import { useBookings } from "../../../../hooks/useBookings";

export default function DeleteBookingModal({
  selectedBooking,
  selectedModal,
  toggleVisibility,
  getBookingsByType,
  setSelectedBooking,
  type,
}) {
  const { deleteBooking } = useBookings();
  return (
    <>
      {selectedBooking && selectedModal === "delete" && (
        <div className="flex flex-col items-center gap-2">
          <h2>
            ¿Estás seguro de eliminar la reserva <b>{selectedBooking.id}</b>?
          </h2>
          <div className="flex justify-center gap-2">
            <button
              className="py-1 px-2 rounded font-medium border border-gray2"
              onClick={toggleVisibility}
            >
              Cancelar
            </button>
            <button
              className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
              onClick={() => {
                deleteBooking({ type, id: selectedBooking.id })
                  .then(() => {
                    getBookingsByType();
                  })
                  .finally(() => {
                    toggleVisibility();
                    setSelectedBooking();
                  });
              }}
            >
              Eliminar
            </button>
          </div>
        </div>
      )}
    </>
  );
}
