
import { generateEmailBodyForOp } from "../utils/bookingPackages/generateEmailBodyForOp";
import useSendEmail from "./useSendEmail";

export const useConfirmBookingEmail = () => {
  const { sendEmail } = useSendEmail();

  const updateBookingAndSendEmail = async (reserva, value, tourOP,email) => {
    try {
      // Send email
      await sendEmail({
        type: "Gmail",
        tour_op_id: tourOP.id,
        options: {
          from: "wearewalltrip@gmail.com",
          bcc: email,
          subject: "Reserva Confirmada",
          html: generateEmailBodyForOp(reserva, tourOP.id, value),
        },
      });
      return { success: true };
    } catch (error) {
      console.error("Error updating booking or sending email:", error);
      return { success: false, error };
    }
  };

  return { updateBookingAndSendEmail };
};
