import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams,  useNavigate, useLocation } from "react-router-dom";
import { saveTourOperator } from "../../../utils/saveTourOperator";
import { useUserStore } from "../../../hooks/useUserStore";
import { Toaster, toast } from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { feeSchema as schema } from "../../../schemas/fees";
import { ErrorMessage } from "@hookform/error-message";
import { useFees } from "../../../hooks/useFees";
import useGetCurrencies from "../../../hooks/useGetCurrencies";
import SEO from "../../../utils/SEO";
import FormTitle from "../../../utils/FormTitle";

export const FormAddFee = () => {
  const { tour_op_id } = useParams();
  const { user } = useUserStore();
  const tourOP = saveTourOperator((st) => st.tourOP.id)
  const location = useLocation();
  const { currencies } = useGetCurrencies();
  const { createFee } = useFees();
  const { tour_id, package_id } = location.state || {};
  const [price, setPrice] = useState (0)
  const navigate = useNavigate();
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const fare = watch("fare", 0); // valor por defecto 0
  const tax = watch("tax", 0); // valor por defecto 0

  useEffect(() => {
    setPrice((fare || 0) + (tax || 0));
  }, [fare, tax]);

  const onSubmit = async (data) => {
    try {
      await toast.promise(
        createFee({
          fee: {
            base: data.base,
            fare: data.fare,
            tax: data.tax,
            price: price,
            currency_type_id: data.currency_type_id,
            id_tours : tour_id,
            tour_op_id: tourOP,
            id_package: package_id,
            user_id: user.id
          },
        }),
        {
          loading: "Cargando...",
          success: "Tarifa creada ccon éxito",
          error: "Hubo un error, inetenta de nuevo.",
        }
      );
      navigate(`/${tour_op_id}/panel/packages/my-packages`, {
        replace: true,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <SEO title={"Agregar Tarifa"} />
      <div className="w-full">
        <FormTitle route={"panel/packages/my-packages"} title={"Agregar Tarifa"} btnText={"Volver a Mis Paquetes"} />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col my-10 mt-16 mx-auto max-w-[1200px] bg-white rounded-lg p-8 gap-4"
        >
          <div>
            <Toaster />
          </div>
          <div className="flex flex-row justify-evenly gap-10">
            <div>
              <h2 className="text-darkBlue font-medium mb-4">
                TIPO DE HABITACÍON
              </h2>
              <input
                className="addPackInput w-full"
                placeholder="Agrega una Habitación.."
                name="base"
                {...register("base")}
              />
              <div className="text-red-600 text-xs my-2 whitespace-nowrap">
                <ErrorMessage errors={errors} name="base" as="p" />
              </div>
            </div>
            <div>
              <h3 className="text-darkBlue font-medium">PRECIO POR PERSONA</h3>
              <div className="grid grid-cols-3 gap-4">
                <label className="relative w-full">
                  <p>Moneda</p>
                  <select
                    name="currency_type_id"
                    id="currency_type_id"
                    className="addPackInput w-full"
                    {...register("currency_type_id", { valueAsNumber: true })}
                  >
                    <option value="">- Elegir Moneda -</option>
                    {currencies?.map(({ name, symbol, id }) => {
                      return (
                        <option key={id} value={id}>
                          {name} ({symbol})
                        </option>
                      );
                    })}
                  </select>
                  <div className="absolute text-red-600 text-xs my-1 whitespace-nowrap">
                    <ErrorMessage
                      errors={errors}
                      name="currency_type_id"
                      as="p"
                    />
                  </div>
                </label>
                <label htmlFor="fare" className="relative w-full">
                  <p>Tarifa</p>
                  <input
                    type="number"
                    min={1}
                    placeholder="Por pasajero"
                    className="addPackInput w-full"
                    name="fare"
                    {...register("fare", { valueAsNumber: true })}
                  />
                  <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                    <ErrorMessage errors={errors} name="fare" as="p" />
                  </div>
                </label>
                <label htmlFor="tax" className="relative w-full">
                  <p>Impuesto</p>
                  <input
                    type="number"
                    min={0}
                    placeholder="Por pasajero"
                    className="addPackInput w-full"
                    name="tax"
                    {...register("tax", { valueAsNumber: true })}
                  />
                  <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                    <ErrorMessage errors={errors} name="tax" as="p" />
                  </div>
                </label>
                <label htmlFor="price" className="relative w-full">
                  <p>Precio Total</p>
                  <input
                    type="number"
                    disabled
                    min={0}
                    className="addPackInput w-full"
                    name="price"
                    value={price}
                  />
                </label>
              </div>
            </div>
          </div>
          <button
            className="button ml-auto mt-5 text-lg w-1/2 bg-darkBlue text-white transition-all"
            type="submit"
          >
            Guardar Tarifa
          </button>
        </form>
      </div>
    </>
  );
};
