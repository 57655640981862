import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import eye from "../../iconos/eye-blue.png";
import trash from "../../iconos/delete.png";
import numberWithCommas from "../../utils/numberWithCommas";

export default function FlightBookingTableRow({
  index,
  v,
  type,
  bookingStatus,
  bookingStatusKeys,
  mutateEstadoReserva,
  openModal,
}) {
  const { tour_op_id } = useParams();
  const { status, id, creation_date, Flights, Travelers } = v;
  if (!Flights || !Flights[0]) return;

  const { options } = Flights[0];
  const { lastTicketingDate, itineraries, price } = JSON.parse(options) ?? {};
  const { departure, carrierCode } = itineraries?.[0]?.segments?.[0];
  const { grandTotal } = price;
  const fsalida = departure.at;
  const { first_name, last_name } = Travelers?.[0];

  return (
    <tbody className="text-sm xl:text-base items-center text-gray">
      <tr
        className={`bg-white text-center border-2 border-lightBlue hover:bg-lightBlue2`}
      >
        <td>{id}</td>
        <td className="mb:hidden lg:table-cell">
          {carrierCode && carrierCode}
        </td>
        <td className="mb:hidden lg:table-cell">
          {lastTicketingDate && lastTicketingDate
            ? format(new Date(lastTicketingDate), "dd/MM/yyyy")
            : "-"}
        </td>
        <td className="mb:hidden lg:table-cell">
          {fsalida ? format(new Date(fsalida), "dd/MM/yyyy") : "-"}
        </td>
        <td className="whitespace-nowrap overflow-hidden mr-4 col-span-2 mb:hidden lg:table-cell">
          {`${last_name} ${first_name}`}
        </td>
        <td className="mb:hidden lg:table-cell">
          {grandTotal && numberWithCommas(Number(grandTotal), 2)}
        </td>
        <td className="mx-auto mb:hidden lg:table-cell">
          {creation_date ? format(new Date(creation_date), "dd/MM/yyyy") : "-"}
        </td>

        <td>
          <select
            className={`rounded-full p-1 pl-2 text-sm ${
              status === bookingStatusKeys[1] && "bg-[#F8DEBF] text-[#E47D01]"
            } ${
              status === bookingStatusKeys[2] &&
              "bg-[#28B15C40]/25 text-[#28B15C]"
            } ${
              status === bookingStatusKeys[3] &&
              "bg-[#DFB72B]/20 text-[#DFB72B]"
            } ${
              status === bookingStatusKeys[4] &&
              "bg-[#BA27FF]/25 text-[#BA27FF]"
            } ${
              status === bookingStatusKeys[5] &&
              "bg-[#2898b1]/25 text-[#2898b1]"
            }
        `}
            value={status}
            onChange={(e) => mutateEstadoReserva({ reserva: v, e })}
          >
            {bookingStatus.slice(1).map(({ key, label }) => (
              <option
                key={key}
                value={key}
                className="text-black bg-white text-sm"
              >
                {label}
              </option>
            ))}
          </select>
        </td>

        <td className="ml-auto">
          <div className="flex justify-center gap-1">
            <Link to={`/${tour_op_id}/panel/sales/bookings/${type}/${id}`}>
              <img src={eye} alt="eye" className="h-7" />
            </Link>
            <img
              src={trash}
              alt="Delete"
              className="h-7 cursor-pointer"
              onClick={() => openModal(v, "delete")}
            />
          </div>
        </td>
      </tr>
    </tbody>
  );
}
