import React from "react";
import SEO from "../../../utils/SEO";
import FormTitle from "../../../utils/FormTitle";
import QuoteForm from "./QuoteForm";

export default function AddQuoteForm() {
  return (
    <div>
      <SEO />
      <FormTitle title={"Agregar Cotización"} btnText={"Volver a Mis Cotizaciones"} route={"panel/sales/quotes/my-quotes/package"} />
      <QuoteForm/>
    </div>
  );
}
