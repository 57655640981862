import { BlobProvider } from "@react-pdf/renderer";
import download from "../../iconos/download.png";
import BookingPackagePdf from "./bookingFiles/BookingPackagePdf";

export default function BookingTableSearcher({
  filters,
  setFilters,
  searchInput,
  setSearchInput,
  bookingStatus,
  type,
  selectedRows,
}) {
  const handleFilters = (e) => {
    const { name, value } = e.target;

    setFilters((curr) => {
      return {
        ...curr,
        [name]: value,
      };
    });
  };

  const handleRadioButtons = (e) => {
    setFilters((curr) => {
      return {
        ...curr,
        radioDate: e.target.name,
      };
    });
  };

  const bookingStatuses = [
    { key: "allBookings", label: "Todas las Reservas" },
    { key: "pending", label: "Pendiente" },

    { key: "confirmed", label: "Confirmado" },
  ];

  return (
    <section className="relative flex pb-4 mb-4 justify-between border-b border-gray2 mb:flex-col lg:flex-row">
      <input
        placeholder="Buscar por origen, destino o pasajero..."
        name="inputText"
        value={searchInput}
        onChange={(evt) => setSearchInput(evt.target.value)}
        className="py-1 px-2 border border-gray2 w-80 rounded"
      />

      <div className="flex items-center gap-4 mb:flex-col lg:flex-row">
        <div className="flex gap-2 mb:flex-col lg:flex-row mb:mt-4 lg:mt-0">
          {type === "flight" && (
            <div className="inline-flex gap-6">
              <label
                htmlFor="creation"
                className="text-sm flex gap-1 items-center mb:flex-col lg:flex-row"
              >
                <input
                  type="radio"
                  checked={filters.radioDate === "creation"}
                  name="creation"
                  id="creation"
                  onChange={handleRadioButtons}
                />
                <p>de Creación</p>
              </label>

              <label
                htmlFor="departure"
                className="text-sm flex gap-1 items-center mb:flex-col lg:flex-row"
              >
                <input
                  type="radio"
                  checked={filters.radioDate === "departure"}
                  name="departure"
                  id="departure"
                  onChange={handleRadioButtons}
                />
                <p>de Salida</p>
              </label>

              <label
                htmlFor="emition"
                className="text-sm flex gap-1 items-center mb:flex-col lg:flex-row"
              >
                <input
                  type="radio"
                  checked={filters.radioDate === "emition"}
                  name="emition"
                  id="emition"
                  onChange={handleRadioButtons}
                />
                <p>Lím. Emisión</p>
              </label>
            </div>
          )}

          <input
            type="date"
            name="inputDate"
            value={filters.inputDate}
            onChange={handleFilters}
            placeholder="Elegir Fecha"
            className="px-3 py-1 border-2 border-gray2 rounded-md h-10"
          />
        </div>
        <span className="flex items-center ">
          <select
            name="inputStatus"
            value={filters.inputStatus}
            onChange={handleFilters}
            className="px-2 py-1 h-9 bg-white border border-gray2 rounded"
          >
            {type === "package" ? (
              <>
                {bookingStatuses.map(({ key, label }) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </>
            ) : (
              <>
                {bookingStatus.map(({ key, label }) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </>
            )}
          </select>
        </span>
        {selectedRows && selectedRows.length > 0 && (
          <>
            <BlobProvider
              document={<BookingPackagePdf pdfData={selectedRows} />}
            >
              {({ url, loading }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <a
                    href={url}
                    download="reservas.pdf"
                    className=" border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors"
                  >
                    <p>PDF</p>
                    <img src={download} alt="download" className="h-8 py-2.5" />
                  </a>
                )
              }
            </BlobProvider>

            {/* <button
                onClick={(e) => handleExport(pdfData)}
                className="border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors"
              >
             Excel
                <img src={download} alt="download" className="h-8 py-2.5" />
              </button> */}
          </>
        )}
        
      </div>
    </section>
  );
}
