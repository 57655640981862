const headers = (type) => [
  "Localizador",
  "Primer Pasajero",

  type === "flight" ? "Lim. Emisión" : "Destino",
  type === "flight" ? "Iata" : "Origen",
  "Neto Agencia",
  "F. Creación",
  "Estado",
  "Acción",
];
export { headers };
