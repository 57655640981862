import React from "react";
import { ModalGeneric } from "../../../../../utils/ModalGeneric";
import ModalViewQuote from "../quoteModal/ModalViewQuote";
import { useParams } from "react-router-dom";
import TableActionsQuotes from "../tableActions/TableActionsQuotes";
import { useToggleVisibility } from "../../../../../hooks/useToggleVisibility";
import { format } from "date-fns";
import SelectQuoteStatus from "./SelectQuoteStatus";
import ico_paquetes from "../../../../../iconos/icono-paquetes.png";
import ico_vuelos from "../../../../../iconos/icono-vuelos.png";
import ico_hoteles from "../../../../../iconos/hotel.png";
import ico_actividades from "../../../../../iconos/actividades.png";

export default function TableQuoteRow({
  qto,
  selectedRows,
  handleRowSelect,
  index,
}) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { tour_op_id } = useParams();


  return (
    <>
      <tr
        className={` text-center border-2 border-lightBlue hover:bg-lightBlue2  ${
          selectedRows.includes(qto) ? "bg-blue-100" : "bg-white"
        }`}
      >
        <td>
          <input
            className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
            type="checkbox"
            checked={selectedRows.includes(qto)}
            onChange={() => handleRowSelect(qto)}
          />
        </td>
        <td>{index+1}</td>
        <td
          className=" p-2 border-2   border-lightBlue "
          onClick={toggleVisibility}
        >
          <div className="flex justify-center">
            {
              qto.type === "flight" ? (
                <img className="w-8" src={ico_vuelos} alt="Flight Icon" />
              ) : qto.type === "package" ? (
                <img className="w-8" src={ico_paquetes} alt="Packages Icon" />
              ) : null // fallback if none match
            }
          </div>
        </td>
        <td 
          className=" p-2 border-2   border-lightBlue "
          onClick={toggleVisibility}
        >
          {qto.name}
        </td>
        {/* <td
          className="p-2 border-2 border-lightBlue"
          onClick={toggleVisibility}
        >
          {qto.passengers}
        </td> */}
        <td
          className="p-2 border-2 border-lightBlue "
          onClick={toggleVisibility}
        >
          {qto.destination}
        </td>
        <td
          className="p-2 border-2 border-lightBlue "
          onClick={toggleVisibility}
        >
          {qto.dates ? qto.dates.split("to")[0] : "N/A"}
        </td>
        {/* <td
          className="p-2 border-2 border-lightBlue "
          onClick={toggleVisibility}
        >
          {qto.passengers}
        </td> */}
        <td
          className="p-2 border-2 border-lightBlue "
          onClick={toggleVisibility}
        >
          {qto.creation_date
            ? format(new Date(qto.creation_date), "dd/MM/yyyy")
            : "N/A"}
        </td>
        <td className="p-2 border-2 border-lightBlue ">
          <SelectQuoteStatus status={qto.status} />
        </td>

        <td className="p-2 flex justify-center ">
          <TableActionsQuotes qto={qto} />
        </td>
      </tr>

      <ModalGeneric toggle={toggleVisibility} show={isVisible}>
        <ModalViewQuote
          selectedQuote={qto}
          children={[
            {
              selectedQuote: qto,
              tour_op_id,
            },
          ]}
        />
      </ModalGeneric>
    </>
  );
}
