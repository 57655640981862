import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import NewCalendar from "../../../utils/NewCalendar";
import { useToggleVisibility } from "../../../hooks/useToggleVisibility";
import { useUserStore } from "../../../hooks/useUserStore";
import { saveTourOperator } from "../../../utils/saveTourOperator";
import SEO from "../../../utils/SEO";
import { tourSchema as schema } from "../../../schemas/tours";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { ErrorMessage } from "@hookform/error-message";
import { Toaster, toast } from "react-hot-toast";
import { useTours } from "../../../hooks/useTours";
import { usePackages } from "../../../hooks/usePackages";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import FormTitle from "../../../utils/FormTitle";

const packagesCategoriesFromScratch = [
  { id: 3, title: "Aventura" },
  { id: 6, title: "Cultural" },
  { id: 1, title: "Deportivo" },
  { id: 2, title: "Educativo" },
  { id: 5, title: "Quinceañera" },
  { id: 4, title: "Religioso" },
];

export const FormAddTour = () => {
  const { user } = useUserStore();
  const tourOP = saveTourOperator((st) => st.tourOP.id);
  const location = useLocation();
  const { tour_op_id } = useParams();
  const { package_id } = location.state || {};
  const { createTour } = useTours();
  const { packagesCategories } = usePackages();
  const [opendateIn, toggleSalida] = useToggleVisibility();
  const [rangeDate, setRangeDate] = useState();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const onSubmit = async (data) => {
    try {
      await toast.promise(
        createTour({
          tour: {
            dateIn: data.dateIn,
            dateOut: data.dateOut,
            hotels: data.hotels,
            category: data.category,
            meal_plan: data.meal_plan,
            days: data.days,
            nights: data.nights,
            stock: data.stock,
            original_stock: data.stock,
            availability: "Y",
            comments: data.comments,
            id_package: package_id,
            user_id: user.id,
            tour_op_id: tourOP,
          },
        }),
        {
          loading: "Procesando...",
          success: "Salida Guardada",
          error: "Hubo un error, intentalo de nuevo!",
        }
      );
      navigate(`/${tour_op_id}/panel/packages/my-packages`, {
        replace: true,
      });
    } catch (e) {
      console.log(e);
      throw new Error();
    }
  };

  return (
    <>
      <SEO title={"Agregar Salida"} />
      <div className="w-full">
        <FormTitle
          route={"panel/packages/my-packages"}
          title={"Agregar Salida"}
          btnText={"Volver a Mis Paquetes"}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="my-10 mt-16 mx-auto max-w-[1200px] bg-white rounded-lg p-8"
        >
          <div>
            <Toaster />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <section className="flex flex-col gap-2">
              <h2 className="text-darkBlue font-medium mb-4">FECHAS</h2>
              <div className="flex flex-row items-start gap-2">
                <label htmlFor="dateIn" className="relative w-3/5">
                  <input
                    id="dateIn"
                    autoComplete="off"
                    name="dateIn"
                    placeholder="Ida"
                    value={
                      watch("dateIn")
                        ? format(new Date(watch("dateIn")), "dd/MM/yyyy")
                        : ""
                    }
                    onClick={() => toggleSalida()} // Optional: Call toggleSalida on click if needed
                    className="addPackInput w-full"
                    // {...register("dateIn")} // Register with react-hook-form
                  />
                  {errors?.dateIn && (
                    <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                      <ErrorMessage errors={errors} name="dateIn" as="p" />
                    </div>
                  )}
                  {opendateIn ? (
                    <NewCalendar
                      handlers={[
                        rangeDate,
                        (date) => {
                          setRangeDate(date);
                          setValue(
                            "dateIn",
                            date?.from ? format(date?.from, "yyyy/MM/dd") : ""
                          );
                          setValue(
                            "dateOut",
                            date?.to ? format(date?.to, "yyyy/MM/dd") : ""
                          );
                          date?.from && date?.to && toggleSalida();
                        },
                      ]}
                      mode={"range"}
                      numberOfMonths={2}
                      close={toggleSalida}
                    />
                  ) : null}
                </label>
              </div>
              <div className="flex flex-col items-start gap-2">
                <label htmlFor="dateOut" className="relative w-3/5">
                  <input
                    id="dateOut"
                    autoComplete="off"
                    name="dateOut"
                    placeholder="Vuelta"
                    value={
                      watch("dateOut")
                        ? format(new Date(watch("dateOut")), "dd/MM/yyyy")
                        : ""
                    }
                    onClick={() => toggleSalida()} // Optional: Call toggleSalida on click if needed
                    className="addPackInput w-full"
                    // {...register("dateOut")} // Register with react-hook-form
                  />
                  <div className="text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                    <ErrorMessage errors={errors} name="dateOut" as="p" />
                  </div>
                </label>
              </div>
              <div>
                <h2 className="text-darkBlue font-medium mb-4">CATEGORÍA</h2>
                <select
                  className="addPackInput w-3/5"
                  name="category"
                  {...register("category")}
                >
                  {packagesCategories &&
                  packagesCategories?.data?.response?.length > 0
                    ? packagesCategories?.data?.response?.map((element) => (
                        <option key={element.id} value={element.title}>
                          {element.title}
                        </option>
                      ))
                    : packagesCategoriesFromScratch?.map((element) => (
                        <option key={element.id} value={element.title}>
                          {element.title}
                        </option>
                      ))}
                </select>
                <div className="text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                  <ErrorMessage errors={errors} name="category" as="p" />
                </div>
              </div>
              <div>
                <h2 className="text-darkBlue font-medium mb-4">ESTADÍA</h2>
                <div className="flex flex-row gap-1">
                  <div className="addPackInput border-[1px] rounded-md p-1 ">
                    <WbSunnyIcon />
                    <input
                      className="ml-2 outline-none"
                      type="number"
                      min={1}
                      name="days"
                      placeholder="Días"
                      {...register("days", { valueAsNumber: true })}
                    />
                  </div>
                  <div className="addPackInput border-[1px] rounded-md p-1 ">
                    <NightsStayIcon />
                    <input
                      className="ml-2 outline-none"
                      type="number"
                      min={1}
                      name="nights"
                      placeholder="Noches"
                      {...register("nights", { valueAsNumber: true })}
                    />
                  </div>
                </div>
                <div className="flex flex-col col-span-2">
                  <label
                    htmlFor="hoteles"
                    className="text-darkBlue mt-3 my-2 relative w-full"
                  >
                    <h2 className="text-darkBlue font-medium mb-4">HOTEL</h2>
                    <textarea
                      id="hotels"
                      name="hotels"
                      {...register("hotels")}
                      className="addPackInput w-full"
                    />
                    <div className="text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                      <ErrorMessage errors={errors} name="hotels" as="p" />
                    </div>
                  </label>
                </div>
              </div>
            </section>
            <section className="relative w-auto h-full flex flex-col gap-4">
              <div className="flex flex-col">
                <h2 className="text-darkBlue font-medium mb-4">
                  REGIMEN COMIDA
                </h2>
                <textarea
                  id="meal_plan"
                  name="meal_plan"
                  {...register("meal_plan")}
                  className="addPackInput w-full"
                />
                <div className="text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                  <ErrorMessage errors={errors} name="meal_plan" as="p" />
                </div>
              </div>
              <div className="flex flex-col col-span-2">
                <h2 className="text-darkBlue font-medium mb-4">COMENTARIOS</h2>
                <textarea
                  id="comments"
                  name="comments"
                  {...register("comments")}
                  className="addPackInput w-full"
                />
                <div className="text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                  <ErrorMessage errors={errors} name="comments" as="p" />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-darkBlue font-medium mr-4">STOCK</label>
                <div className="addPackInput w-1/5 border-[1px] rounded-md">
                  <input
                    className="w-4/5 ml-2 outline-none"
                    type="number"
                    min={1}
                    name="stock"
                    placeholder="stock"
                    {...register("stock", { valueAsNumber: true })}
                  />
                </div>
              </div>
              <button className="button ml-auto absolute bottom-0 right-0 text-lg w-1/2 bg-darkBlue text-white hover:shadow-lg transition-all">
                Guardar Salida
              </button>
            </section>
          </div>
        </form>
      </div>
    </>
  );
};
