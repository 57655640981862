import React from "react";
import { MdEmail } from "react-icons/md";
import { ModalGeneric } from "../../../../../../utils/ModalGeneric";
import { useToggleVisibility } from "../../../../../../hooks/useToggleVisibility";
import { saveTourOperator } from "../../../../../../utils/saveTourOperator";
import SendEmail from "../../../../../../utils/sendAgenciesEmail/SendEmail";

export default function EmailButton({ pkg }) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const tourOP = saveTourOperator((st) => st.tourOP);
  return (
    <button
      className="relative mx-0.5 h-7 w-7 flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors group/link"
      onClick={toggleVisibility}
    >
      <MdEmail size={23} />
      <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/send:scale-100">
        Enviar a Agencias
      </span>
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <SendEmail
          selectedPackage={pkg}
          tourOP={tourOP}
          closeModal={toggleVisibility}
        />
      </ModalGeneric>
    </button>
  );
}
