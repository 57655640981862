import React, { useEffect, useState } from "react";
import TablePackagesHeader from "./TablePackagesHeader";
import { usePackages } from "../../../../hooks/usePackages";
import { saveTourOperator } from "../../../../utils/saveTourOperator";
import TablePackagesBody from "./TablePackagesBody";
import Pagination from "../../../../utils/Pagination";
import TablePackagesFiltering from "./tablePackagesFiltering/TablePackagesFiltering";
import useReloadStore from "../../../../store/useRealoadStore";
import { format } from "date-fns";

export default function TablePackages() {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { packages, getPackages } = usePackages();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searcherValue, setSearcherValue] = useState({
    textValue: "",
    monthValue: "",
  });
  const [tour, setTour] = useState([]);
  const reloadPackages = useReloadStore((state) => state.reloadPackages);
  const lowerSearch = searcherValue.textValue.toLowerCase();

  useEffect(() => {
    getPackages();
  }, [tourOP, reloadPackages]);

  const filteredPackages = packages
    .filter((pack) => {
      const { title, destination_code, origin_code } = pack;

      return (
        title.toLowerCase().includes(lowerSearch) ||
        origin_code.toLowerCase().includes(lowerSearch) ||
        destination_code.toLowerCase().includes(lowerSearch)
      );
    })
    .filter((pack) => {
      if (!searcherValue.monthValue) return true;

      if (!tour?.dateIn) return false;

      const formattedDate = format(tour.dateIn, "dd-MM-yyyy");

      const [packMonth, packYear] = formattedDate.split("-");
      const [searchYear, searchMonth] = searcherValue.monthValue.split("-");

      return packYear === searchYear && packMonth === searchMonth;
    })
    .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);

  const [tableHeaders] = useState([
    "Paquete #",
    "Name",
    "Origen",
    "Destino",
    "Acciones",
  ]);

  return (
    <>
      <div className="p-4 border-2 border-gray2 bg-white rounded-lg">
        <TablePackagesFiltering
          searcherValue={searcherValue}
          setSearcherValue={setSearcherValue}
        />
        <table className="w-full border-collapse  rounded-lg overflow-hidden bg-lightBlue2 ">
          <TablePackagesHeader cols={tableHeaders} />
          <TablePackagesBody setTour={setTour} packages={filteredPackages} />
        </table>
      </div>
      <Pagination
        items={packages}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
