import { ErrorMessage } from "@hookform/error-message";
import React, { useState, useEffect } from "react";
import FormEditAddPicture from "../../FormEditAddPicture";
import { AutoComplete } from "../../../../utils/AutoComplete";
import PortraitHandler from "./PortraitHandler";
import FormTitle from "../../../../utils/FormTitle";

// const suppliers = [
//   { id: 3, title: "Proveedor 1" },
//   { id: 6, title: "Proveedor 2" },
//   { id: 1, title: "Proveedor 3" },
// ];

export default function PackageSubForm({
  register,
  setValue,
  errors,
  watch,
  clearErrors,
}) {
  const [oricodeInput, setOricodeInput] = useState("");
  const [descodeInput, setDescodeInput] = useState("");
  const [pictures, setPictures] = useState([]);
  const [portrait, setPortrait] = useState();
  const [flyerDataUrl, setFlyerDataUrl] = useState(null);

  const MAX_SIZE_MB = 2; // Tamaño máximo del flyer y fotos

  const destinationCodeValue = watch("packageSchema.destination_code");
  const originCodeValue = watch("packageSchema.origin_code");

  useEffect(() => {
    // Si `destination_code` o `origin_code` tiene un valor, borra los mensajes de error
    if (destinationCodeValue) {
      clearErrors("packageSchema.destination_code");
    }
    if (originCodeValue) {
      clearErrors("packageSchema.origin_code");
    }
  }, [destinationCodeValue, originCodeValue, clearErrors]);

  // Comprimir imagen individual
  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800;
          const scaleSize = MAX_WIDTH / image.width;
          canvas.width = MAX_WIDTH;
          canvas.height = image.height * scaleSize;

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          let quality = 0.9;
          let compressedDataUrl = canvas.toDataURL("image/jpeg", quality);

          while (
            compressedDataUrl.length / 1024 / 1024 > MAX_SIZE_MB &&
            quality > 0.1
          ) {
            quality -= 0.1;
            compressedDataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          resolve(compressedDataUrl); // Debe resolver solo la URL
        };
      };
      reader.readAsDataURL(file);
    });
  };

  // Manejador para la carga de imágenes del flyer
  const handleFlayerChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const compressedFlyer = await compressImage(file);
      setFlyerDataUrl(compressedFlyer);
      setValue("packageSchema.flyer", compressedFlyer);
    }
  };

  //Manejador para la carga de múltiples imágenes del paquete
  const handlePicturesChange = async (event) => {
    const files = Array.from(event.target.files); // Convertir a array
    const compressedImages = await Promise.all(
      files.map((file) => compressImage(file)) // Comprimir cada imagen
    );

    const validImages = compressedImages.filter((img) => img !== null); // Filtrar imágenes válidas

    // Actualizar el estado de imágenes
    setPictures((prevPictures) => {
      const updatedPictures = [...prevPictures, ...validImages];

      // Actualizar el valor del formulario solo con las URLs (strings)
      setValue("packageSchema.pictures", updatedPictures.slice(1)); // Esto ya contiene solo strings
      return updatedPictures; // Retornar el nuevo estado
    });

    // Reiniciar el input
    event.target.value = null;
  };

  const handlePortraitChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const compressedPortrait = await compressImage(file);
      setPortrait(compressedPortrait);
      setValue("packageSchema.portrait", compressedPortrait);
    }
  };

  return (
    <div className="w-full">
      <FormTitle
        route={"panel/packages/my-packages"}
        title={"Agregar Paquete"}
        btnText={"Volver a Mis Paquetes"}
      />

      <div className="grid grid-cols-12 gap-12 my-10 mt-16 mx-auto max-w-[1200px] bg-white rounded-lg p-8 ">
        <section className="flex flex-col gap-4 col-span-5">
          <h2 className="text-darkBlue font-medium">INFORMACIÓN GENERAL</h2>
          <div className="grid grid-cols-2 gap-4">
            <label className="col-span-2 relative">
              <input
                placeholder="Ingresar el nombre completo del paquete"
                className="addPackInput w-full"
                name="title"
                {...register("packageSchema.title")}
              />
              {errors?.title && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <ErrorMessage errors={errors} name="title" as="p" />
                </div>
              )}
            </label>

            <label className="relative">
              <AutoComplete
                justCities
                notIcon
                classname="addPackInput w-full"
                register={register}
                name="packageSchema.origin_code"
                placeholder="Origen"
                handleAction={(d) =>
                  setValue("packageSchema.origin_code", d.toUpperCase())
                }
                inputState={[oricodeInput, setOricodeInput]}
              />
              {errors?.origin_code && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <ErrorMessage errors={errors} name="origin_code" as="p" />
                </div>
              )}
            </label>

            <label className="relative">
              <AutoComplete
                justCities
                notIcon
                classname="addPackInput w-full"
                register={register}
                name="packageSchema.destination_code"
                placeholder="Destino"
                handleAction={(d) =>
                  setValue("packageSchema.destination_code", d.toUpperCase())
                }
                inputState={[descodeInput, setDescodeInput]}
              />
              {errors?.destination_code && (
                <div className="text-red-600 text-xs whitespace-nowrap ml-2 my-1">
                  <ErrorMessage
                    errors={errors}
                    name="destination_code"
                    as="p"
                  />
                </div>
              )}
            </label>

            <div className="flex flex-col col-span-2">
              <label className="text-darkBlue mt-3 my-2 relative w-full">
                <p className="font-medium">DESCRIPCIÓN</p>
                <textarea
                  id="description_general"
                  name="description_general"
                  {...register("packageSchema.description_general")}
                  className="addPackInput w-full"
                />
              </label>
            </div>
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-darkBlue mt-3 my-2 relative w-full">
              <p className="font-medium">INCLUYE</p>
              <textarea
                id="include"
                name="include"
                {...register("packageSchema.include")}
                className="addPackInput w-full"
              />
            </label>
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-darkBlue mt-3 my-2 relative w-full">
              <p className="font-medium">NO INCLUYE</p>
              <textarea
                id="not_included"
                name="not_included"
                {...register("packageSchema.not_included")}
                className="addPackInput w-full"
              />
            </label>
          </div>
        </section>

        <section className="relative col-span-7 pb-16">
          <div className="flex flex-col col-span-2">
            <label className="text-darkBlue mt-3 my-2 relative w-full">
              <p className="font-medium">ITINERARIO</p>
              <textarea
                id="itinerary"
                name="itinerary"
                {...register("packageSchema.itinerary")}
                className="addPackInput w-full"
              />
            </label>
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-darkBlue mt-3 my-2 relative w-full">
              <p className="font-medium">TERMINOS</p>
              <textarea
                id="terms"
                name="terms"
                {...register("packageSchema.terms")}
                className="addPackInput w-full"
              />
            </label>
          </div>
          <h2 className="text-darkBlue font-medium mb-4">
            PORTADA DEL PAQUETE
          </h2>
          <div className="flex flex-col gap-2 items-center mb-4">
            <input
              type="file"
              accept="image/*"
              onChange={handlePortraitChange}
              multiple // Permite cargar múltiples imágenes
              className="addPackInput w-full"
            />
          </div>
          <PortraitHandler portrait={portrait} setPortrait={setPortrait} />

          <h2 className="text-darkBlue font-medium mb-4">FOTOS DEL PAQUETE</h2>
          <div className="flex flex-col gap-2 items-center mb-4">
            <input
              type="file"
              accept="image/*"
              onChange={handlePicturesChange}
              multiple // Permite cargar múltiples imágenes
              className="addPackInput w-full"
            />
          </div>
          <FormEditAddPicture pictures={pictures} setPictures={setPictures} />

          <h2 className="text-darkBlue font-medium my-4">FLYER</h2>
          <div className="flex flex-col gap-2 items-center mb-4">
            <input
              type="file"
              accept="image/*"
              onChange={handleFlayerChange}
              className="addPackInput w-full"
            />
            {flyerDataUrl && (
              <>
                <img
                  src={flyerDataUrl}
                  alt="Selected"
                  className="max-w-[16rem] max-h-[500px]"
                />
              </>
            )}
          </div>
          {/* <div>
            <h2 className="text-darkBlue font-medium my-4">PROVEEDOR</h2>
            <select
              className="addPackInput w-2/5"
              name="supplier_id"
              {...register("packageSchema.supplier_id", {
                valueAsNumber: true,
              })}
            >
              <option value={0}>- Proveedores -</option>
              {suppliers.length > 0 ? (
                suppliers.map((element) => (
                  <option key={element.id} value={element.id}>
                    {element.title}
                  </option>
                ))
              ) : (
                <option value="">No hay proveedores disponibles</option>
              )}
            </select>
          </div> */}
          <div className="flex flex-row gap-3">
            <label className="text-darkBlue font-medium my-4">PUBLICAR</label>
            <input
              type="checkbox"
              name="show"
              defaultChecked={true}
              className="scale-150"
              {...register("packageSchema.show")}
            />
          </div>
        </section>
      </div>
    </div>
  );
}
