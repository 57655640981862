import React from "react";

import DeleteBookingModal from "./subModales/DeleteBookingModal";
import ConfirmFlightBookingModal from "./subModales/ConfirmFlightBookingModal";
import ConfirmPackageModal from "./subModales/ConfirmPackageModal";

export default function ModalBooking({
  selectedBooking,
  selectedModal,
  toggleVisibility,
  getBookingsByType,
  setSelectedBooking,
  showError,
  setShowError,
  setLoading,
  updateBookingState,
  loading,
  type,
}) {
  return (
    <>
      <DeleteBookingModal
        selectedBooking={selectedBooking}
        selectedModal={selectedModal}
        toggleVisibility={toggleVisibility}
        getBookingsByType={getBookingsByType}
        setSelectedBooking={setSelectedBooking}
        type={type}
      />
      <ConfirmFlightBookingModal
        selectedModal={selectedModal}
        selectedBooking={selectedBooking}
        showError={showError}
        toggleVisibility={toggleVisibility}
        setShowError={setShowError}
        setLoading={setLoading}
        updateBookingState={updateBookingState}
        setSelectedBooking={setSelectedBooking}
        getBookingsByType={getBookingsByType}
        loading={loading}
      />
       <ConfirmPackageModal
        selectedModal={selectedModal}
        selectedBooking={selectedBooking}
        showError={showError}
        toggleVisibility={toggleVisibility}
        setShowError={setShowError}
        setLoading={setLoading}
        updateBookingState={updateBookingState}
        setSelectedBooking={setSelectedBooking}
        getBookingsByType={getBookingsByType}
        loading={loading}
      />
    </>
  );
}
