import { z } from "zod";

export const feeSchema = z.object({
  base: z.string().min(1, { message: "Campo requerido" }),
  fare: z
    .number({ invalid_type_error: "Ingresa una Tarifa" })
    .refine((value) => value > 0, { message: "Ingresa una Tarifa" }),
  tax: z
    .number({ invalid_type_error: "Ingresa un Impuesto" })
    .refine((value) => value >= 0, { message: "Ingresa un Impuesto" }),
  currency_type_id: z
    .number({
      required_error: "Selecciona un tipo de moneda", // Message when value is missing
      invalid_type_error: "Selecciona un tipo de moneda", // Message for wrong type
    })
    .positive({ message: "Selecciona un tipo de moneda válida" }),
});
