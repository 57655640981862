import React from "react";
import { useNavigate } from "react-router-dom";

export default function BookingSelector({ tour_op_id, type }) {
  const navigate = useNavigate();
  return (
    <div className="relative flex items-center border-2 border-lightBlue rounded-3xl h-12">
      <button
        className="px-8 rounded-3xl w-32 z-10 text-lg"
        onClick={() => navigate(`/${tour_op_id}/panel/sales/bookings/flight`)}
      >
        Vuelos
      </button>
      <button
        className="px-8 rounded-3xl w-32 z-10 text-lg"
        onClick={() => navigate(`/${tour_op_id}/panel/sales/bookings/package`)}
      >
        Paquetes
      </button>
      <button
        className="px-8 rounded-3xl w-32 z-10 text-lg"
        onClick={() => navigate(`/${tour_op_id}/panel/sales/bookings/hotel`)}
      >
        Hoteles
      </button>
      <span
        className={`absolute h-12 w-32 bg-lightBlue transition-all rounded-3xl ${
          type === "flight"
            ? "left-0"
            : type === "package"
            ? "left-32"
            : "left-64"
        }`}
      />
    </div>
  );
}
