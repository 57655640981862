import React from "react";
import { MdLink } from "react-icons/md";
import { useCopy } from "../../hooks/utils/useCopy";

export default function CopyLinkButton({ data, tour_op_id, route }) {
  const [isCopied, copyToClipboard] = useCopy();
  const currentHREF = window.location.href.split("/").slice(0, -3).join("/");
  return (
    <button
      to={`/${tour_op_id}/${route}/${data.id}`}
      className="relative mx-0.5 h-7 w-7 flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors group/link"
      onClick={() =>
        copyToClipboard(`${currentHREF}/${route}/${data.id}`)
      }
    >
      <MdLink scale={23} />
      <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/link:scale-100">
        Copiar Enlace
      </span>
    </button>
  );
}
