import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { supplierSchema as schema } from "../../../schemas/newSupplier";
import { ErrorMessage } from "@hookform/error-message";
import { useSupplier } from "../../../hooks/useSupplier";
import SEO from "../../../utils/SEO";
import FormTitle from "../../../utils/FormTitle";

export const FormAddSupplier = () => {
  const { tour_op_id } = useParams();
  const { createSupplier } = useSupplier();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      data.tax_number = Number(data.tax_number);

      await toast.promise(
        createSupplier({
          supplier: {
            name: data.name,
            legal_name: data.legal_name,
            email: data.email,
            tax_code: data.tax_code,
            tax_number: data.tax_number,
            address: data.address,
            account_link: data.account_link,
            phone_number: data.phone_number,
            contact_name: data.contact_name,
            cellphone_number: data.cellphone_number,
            tour_op_id: 1,
          },
        }),
        {
          loading: "Cargando...",
          success: "Proveedor guardado con éxito",
          error: "Hubo un error, intenta de nuevo.",
        }
      );
      navigate(`/${tour_op_id}/panel/suppliers/my-suppliers`, {
        replace: true,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <SEO title={"Agregar Proveedor"} />

      <FormTitle
        route={"panel/suppliers/my-suppliers"}
        title={"Agregar Proveedor"}
        btnText={" Mis Proveedores"}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="my-10 mx-auto max-w-[1000px] bg-white rounded-lg p-8"
      >
        <Toaster position="top-right" reverseOrder={false} />

        <main className="grid grid-cols-2 gap-6 font-body">
          <section className="flex flex-col col-span-1 gap-6">
            <div>
              <label htmlFor="name" className="w-full">
                Nombre
              </label>
              <input
                placeholder="Ingresar el nombre del proveedor"
                className="addPackInput w-full"
                {...register("name")}
              />
              <div className="text-red-600 text-xs mt-1">
                <ErrorMessage errors={errors} name="name" as="p" />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="w-full">
                Email
              </label>
              <input
                placeholder="Ingresar el nombre del proveedor"
                className="addPackInput w-full"
                {...register("email")}
              />
              <div className="text-red-600 text-xs mt-1">
                <ErrorMessage errors={errors} name="email" as="p" />
              </div>
            </div>
            <div>
              <label htmlFor="tax_code" className="w-full">
                Cuenta Fiscal
              </label>
              <input
                placeholder="CIF/CUIT/EIN"
                className="addPackInput w-full"
                {...register("tax_code")}
              />
              <div className="text-red-600 text-xs mt-1">
                <ErrorMessage errors={errors} name="tax_code" as="p" />
              </div>
            </div>
            <div>
              <label htmlFor="contact_name" className="w-full">
                Nombre de Contacto
              </label>
              <input
                placeholder="Ingresar el nombre del contacto"
                className="addPackInput w-full"
                {...register("contact_name")}
              />
              <div className="text-red-600 text-xs mt-1">
                <ErrorMessage errors={errors} name="contact_name" as="p" />
              </div>
            </div>
            <div>
              <label htmlFor="cellphone_number" className="w-full">
                Número Celular
              </label>
              <input
                placeholder="Ingresar el número celular"
                className="addPackInput w-full"
                {...register("cellphone_number")}
              />
              <div className="text-red-600 text-xs mt-1">
                <ErrorMessage errors={errors} name="cellphone_number" as="p" />
              </div>
            </div>
          </section>
          <section className="flex flex-col col-span-1 gap-6">
            <div>
              <label htmlFor="legal_name" className="w-full">
                Nombre Legal
              </label>
              <input
                placeholder="Ingresar el nombre legal del proveedor"
                className="addPackInput w-full"
                {...register("legal_name")}
              />
              <div className="text-red-600 text-xs mt-1">
                <ErrorMessage errors={errors} name="legal_name" as="p" />
              </div>
            </div>
            <div>
              <label htmlFor="address" className="w-full">
                Dirección
              </label>
              <input
                placeholder="Ingresar la dirección"
                className="addPackInput w-full"
                {...register("address")}
              />
              <div className="text-red-600 text-xs mt-1">
                <ErrorMessage errors={errors} name="address" as="p" />
              </div>
            </div>
            <div>
              <label htmlFor="tax_number" className="w-full">
                Número de Cuenta
              </label>
              <input
                placeholder="Ingresar el número de cuenta"
                className="addPackInput w-full"
                {...register("tax_number")}
              />
              <div className="text-red-600 text-xs mt-1">
                <ErrorMessage errors={errors} name="tax_number" as="p" />
              </div>
            </div>
            <div>
              <label htmlFor="account_link" className="w-full">
                Link de Cuenta
              </label>
              <input
                placeholder="Ingresar el nombre del proveedor"
                className="addPackInput w-full"
                {...register("account_link")}
              />
              <div className="text-red-600 text-xs mt-1">
                <ErrorMessage errors={errors} name="account_link" as="p" />
              </div>
            </div>
            <div>
              <label htmlFor="phone_number" className="w-full">
                Número de Teléfono
              </label>
              <input
                placeholder="Ingresar el nombre del proveedor"
                className="addPackInput w-full"
                {...register("phone_number")}
              />
              <div className="text-red-600 text-xs mt-1">
                <ErrorMessage errors={errors} name="phone_number" as="p" />
              </div>
            </div>
            <button className="button w-fit col-span-1 ml-auto text-lg bg-darkBlue text-white hover:shadow-lg transition-all px-2">
              Guardar Proveedor
            </button>
          </section>
        </main>
      </form>
    </>
  );
};
