import Head from "../head";
import { useState } from "react";
import SEO from "../../utils/SEO";
import Anicarga from "../anicarga";
// import axios from "axios";
import { Andalucia } from "./andalucia";
import { CardActividad } from "./CardActividad";
import Pagination from "../../utils/Pagination";
import FiltrosActividades from "./FiltrosActividades";
import ActividadSeleccionada from "./ActividadSeleccionada";

export default function Actividades() {
  // const { paquetes, loading, postPaquete } = usePaquetes();
  // const { operador } = useParams();
  // const packageSelected = useSelectPaquetes((st) => st.packageSelected);
  // const selectPackage = useSelectPaquetes((st) => st.selectPackage);
  // const location = useLocation();
  // const navigate = useNavigate();
  // const [parent] = useAutoAnimate();

  // useEffect(() => {
  //   selectPackage({});
  // }, [location.search]);

  // Constante hasta que logre funcionar la API

  // useEffect(() => {
  //   const getAndaluciaActiivities = async () => {
  //     return await axios
  //       .get(
  //         "https://maps.andalucia.org/rest-turistico/rest/guided_activity_company/list",
  //         {
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       )
  //       .then((response) => console.log(response.data))
  //       .catch((error) => console.log("error", error));
  //   };

  //   getAndaluciaActiivities();
  // }, []);

  // const crearReserva = (p) => {
  //   postPaquete(p).then((e) =>
  //     navigate(`/${operador}/Detalle?type=paquete&offerId=${e.data.insertId}`)
  //   );
  // };
  const actividades = Andalucia.list;
  // const cities = [];
  const citiesByProvince = {};

  actividades.forEach((item) => {
    const city = item.territories[0].name;
    const province = item.territories[0].province_name;

    // if (!cities.includes(city)) {
    //   cities.push(city);
    // }

    if (!citiesByProvince[province]) {
      citiesByProvince[province] = [city];
    } else {
      if (!citiesByProvince[province].includes(city)) {
        citiesByProvince[province].push(city);
      }
    }
  });

  const [filters, setFilters] = useState({
    category: [],
    province: [],
    city: [],
  });
  const [selectedActivity, setSelectedActivity] = useState(null);

  const filteredActivities = actividades.filter((item) => {
    const { province, city } = filters;
    const territories = item.territories[0];

    if (city.length === 0 && province.length === 0) return true;
    if (city.length === 0) {
      return province.includes(territories.province_name);
    }
    if (province.length === 0) {
      return city.includes(territories.name);
    }

    return (
      city.includes(territories.name) &&
      province.includes(territories.province_name)
    );
  });

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const loading = false; // en un futuro va a ser un state

  return (
    <>
      <SEO title={"Actividades"} />
      <Head />
      <main className="flex gap-12 my-10 ml-[92px] mr-16">
        <FiltrosActividades
          filters={filters}
          setFilters={setFilters}
          citiesByProvince={citiesByProvince}
        />
        {loading && actividades.length === 0 ? (
          <div className="w-full flex mt-28 justify-center">
            <Anicarga />
          </div>
        ) : (
          <section className="flex flex-col gap-8 w-full">
            <section className="flex gap-8 w-full">
              <div
                className={`flex flex-col gap-4 mt-10 transition-all ${
                  selectedActivity ? "w-3/4" : "w-full"
                }`}
              >
                {filteredActivities
                  .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
                  .map((act) => (
                    <CardActividad
                      key={act.id}
                      actividad={act}
                      selectedActivity={selectedActivity}
                      setSelectedActivity={setSelectedActivity}
                    />
                  ))}
              </div>

              <ActividadSeleccionada activity={selectedActivity} />
            </section>
            <Pagination
              items={filteredActivities}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              page={page}
              setPage={setPage}
            />
          </section>
        )}
      </main>
    </>
  );
}
