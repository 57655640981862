import { useState } from "react";
import Pagination from "../../utils/Pagination";
import MisTravelersBusqueda from "./MisTravelersBusqueda";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { useTravelers } from "../../hooks/useTravelers";
import { ModalGeneric } from "../../utils/ModalGeneric";
import UpdateTravelerModal from "./UpdateTravelerModal";
import TravelerRow from "./table/TravelerRow";
import TravelersFilters from "./TravelersFilters";
import ExportButtonsTraveler from "./table/tableFilter/ExportButtonsTraveler";
import TravelerHeader from "./table/TravelerHeader";

export default function TravelersTable({
  travelers,
  searcher,
  setPage,
  page,
  refreshValues,
  agencies,
  filteredUsers,
  user,
  filters,
  setFilters,
  selectedOperator,
  setSelectedOperator,
  tourOP,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const [typeModal, setTypeModal] = useState("");
  const [selectedTraveler, setSelectedTraveler] = useState("");
  const { traveler, getTraveler } = useTravelers();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const openModal = async (travelerId, modal) => {
    await getTraveler(travelerId.id); // Wait for the traveler data to be fetched
    setSelectedTraveler(traveler); // Update the selected traveler state
    setTypeModal(modal); // Set the modal type
    toggleVisibility(); // Toggle the modal visibility
  };

  const closeModal = async () => {
    await refreshValues(); // Ensure travelers are updated before closing
    toggleVisibility(); // Toggle the modal visibility
  };

  const handleRowSelect = (traveler) => {
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.some((row) => row.id === traveler.id);
      return isSelected
        ? prevSelected.filter((row) => row.id !== traveler.id)
        : [...prevSelected, traveler];
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Select all travelers for the current page

      setSelectedRows(travelers);
    } else {
      // Deselect all travelers
      setSelectedRows([]);
    }
  };

  return (
    <>
      <section className="p-4 border-2 border-gray2 bg-white rounded-lg ">
        <div className="flex flex-row">
          <MisTravelersBusqueda searcher={searcher} pdfData={selectedRows} />

          <TravelersFilters
            dataToUse={[agencies, filteredUsers]}
            user={user}
            filtersState={[filters, setFilters]}
            tourOperatorState={[selectedOperator, setSelectedOperator]}
            tourOP={tourOP}
          />
          <ExportButtonsTraveler pdfData={selectedRows} />
        </div>

        <table className="min-w-full border-collapse">
          <thead className="bg-lightBlue">
            <TravelerHeader
              handleSelectAll={handleSelectAll}
              travelers={travelers}
              page={page}
              selectedRows={selectedRows}
            />
          </thead>
          <tbody>
            {travelers
              ?.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
              ?.map((traveler, index) => (
                <>
                  <TravelerRow
                    selectedTraveler={selectedTraveler}
                    traveler={traveler}
                    selectedRows={selectedRows}
                    handleRowSelect={handleRowSelect}
                    openModal={openModal}
                    key={index}
                  />
                </>
              ))}
          </tbody>
        </table>
        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          {typeModal === "edit" && (
            <UpdateTravelerModal traveler={traveler} closeModal={closeModal} />
          )}
        </ModalGeneric>
      </section>

      <Pagination
        items={travelers}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
