import React from "react";
import { useBookings } from "../../../../hooks/useBookings";
import clsx from "clsx";

export default function ConfirmFlightBookingModal({
  selectedModal,
  selectedBooking,
  showError,
  toggleVisibility,
  setShowError,
  setLoading,
  updateBookingState,
  setSelectedBooking,
  getBookingsByType,
  loading,
}) {
  const { errorPNR, postAmadeusBooking } = useBookings();
  return (
    <>
      {selectedBooking && selectedModal === "confirm_PNR" && (
        <div className="flex flex-col items-center gap-2">
          {!showError ? (
            <>
              <h2>
                ¿Estás seguro de confirmar la reserva{" "}
                <b>{selectedBooking.id}</b>?
              </h2>
              <div className="flex justify-center gap-2">
                <button
                  className="py-1 px-2 rounded font-medium border border-gray2"
                  onClick={toggleVisibility}
                >
                  Cancelar
                </button>
                <button
                  onClick={() => {
                    setShowError(false);
                    setLoading(true);
                    postAmadeusBooking({ booking: selectedBooking })
                      .then((e) => {
                        const isError = e?.response?.result?.errors || false;

                        if (isError) {
                          setShowError(true);
                          return;
                        }

                        updateBookingState(
                          selectedBooking,
                          "Confirmado"
                        ).finally(() => {
                          toggleVisibility();
                          setSelectedBooking();
                          getBookingsByType();
                        });
                      })
                      .finally(() => setLoading(false));
                  }}
                  className={clsx(
                    {
                      "opacity-75 text-white cursor-not-allowed pointer-events-none":
                        loading,
                    },
                    "py-1 px-2 rounded font-medium border border-brightBlue bg-brightBlue text-white"
                  )}
                >
                  Confirmar Reserva
                  {loading ? (
                    <svg
                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        stroke="currentColor"
                        stroke-width="4"
                        cx="12"
                        cy="12"
                        r="10"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                </button>
              </div>
            </>
          ) : (
            <h1>
              {errorPNR?.[0]?.title}: {errorPNR?.[0]?.detail}
            </h1>
          )}
        </div>
      )}
    </>
  );
}
