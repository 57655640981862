import { z } from "zod";

export const quoteSchema = z.object({
    name: z.string().min(1, { message: "Campo incompleto" }),
    origin: z.string().optional(),
    destination: z.string().optional(),
    email: z.string().min(1, { message: "Campo incompleto" }),
    whatsapp: z.string().min(1, { message: "Campo incompleto" }),
    dates: z.string().min(1, { message: "Campo incompleto" }),
    ask: z.string().optional(),
    type: z.string().min(1, { message: "Campo incompleto" }),
    passengers: z.string().min(1, { message: "Campo incompleto" }),
    channel: z.string().default("manual"),
});
