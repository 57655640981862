import React from "react";
import SEO from "../../../utils/SEO";
import PageTitle from "../../../utils/PageTitle";
import TableQuotes from "./tableQuote/TableQuotes";

export default function AdminQuotes() {
  return (
    <div className="mt-8">
      <SEO title={"Mis Cotizaciones"} />
      <PageTitle
        title={"Mis Cotizaciones"}
        subTitle={" Dashboard Overview."}
        route={"panel/sales/quotes/my-quotes/add-quote"}
        btnText={"Agregar Cotización"}
        buttonVisivility={true}
      />
      <TableQuotes/>
    </div>
  );
}
