import { format } from "date-fns";
import React from "react";

export default function ModalViewQuote({ selectedQuote }) {
  const creationDateFormatted = selectedQuote.creation_date
    ? format(new Date(selectedQuote.creation_date), "dd/MM/yyyy")
    : "N/A";

  const datesFormatted = selectedQuote.dates ? selectedQuote.dates : "N/A";

  const currency = selectedQuote.quote?.currency || "N/A"; // Default to a message if currency is null

  const totalAmountFormatted = selectedQuote.quote?.totalAmount
    ? `${currency} ${selectedQuote.quote.totalAmount}`
    : "N/A";

  const breakdown = selectedQuote.quote?.breakdown || {
    taxes: 0,
    flights: 0,
    services: 0,
  };

  const statusMap = {
    "to-start": { text: "Para iniciar", bgColor: "bg-yellow-100" },
    "quote-asked": { text: "Pedido coti", bgColor: "bg-blue-100" },
    "info-missing": { text: "Falta info", bgColor: "bg-red-100" },
    "no-answer": { text: "Sin respuesta", bgColor: "bg-gray-100" },
    "quote-sent": { text: "Coti enviada", bgColor: "bg-green-100" },
    "ask-info": { text: "Pedir datos", bgColor: "bg-orange-100" },
    "pending": { text: "Pendiente", bgColor: "bg-purple-100" },
  };

  const statusInfo = statusMap[selectedQuote.status] || {
    text: "Desconocido",
    bgColor: "bg-gray-200",
  };

  return (
    <div className="">
      <h2 className="text-xl font-semibold">Detalle de Cotizacion</h2>

      <div className="mt-4">
        <h3 className="font-bold">Pasajeros</h3>
        <p>{selectedQuote.passengers}</p>
      </div>

      <div className="mt-4">
        <h3 className="font-bold">Detalles del Viaje</h3>
        <p>
          <span className="font-medium">Origen:</span> {selectedQuote.origin || "No especificado"}
        </p>
        <p>
          <span className="font-medium">Destino:</span>{" "}
          {selectedQuote.destination || "No especificado"}
        </p>
        <p>
          <span className="font-medium">Fechas:</span> {datesFormatted}
        </p>
      </div>

      <div className="mt-4">
        <h3 className="font-bold">Pedido</h3>
        <p>
          {selectedQuote.ask || "No especificado"}
        </p>
      </div>
      {/* <div className="mt-4">
        <h3 className="font-bold">Cotizacion</h3>
        <p><strong>Vuelos:</strong> {`${currency} ${breakdown.flights}`}</p>
        <p><strong>Impuesto:</strong> {`${currency} ${breakdown.taxes}`}</p>
        <p><strong>Servicio:</strong> {`${currency} ${breakdown.services}`}</p>
        <p><strong>Total:</strong> {totalAmountFormatted}</p>
      </div> */}

      <div className="mt-4">
        <h3 className="font-bold">Informacion de Contacto</h3>
        <p>
          <span className="font-medium">Nombre:</span> {selectedQuote.name || "No Disponible"}
        </p>
        <p>
          <span className="font-medium">Email:</span> {selectedQuote.email || "No Disponible"}
        </p>
        <p>
          <span className="font-medium">WhatsApp:</span> {selectedQuote.whatsapp || "No Disponible"}
        </p>
      </div>

      <div className="mt-4">
        <h3 className="font-bold">Informacion Adicional</h3>
        <p>
          <span className="font-medium">Estado de Cotización:</span>{" "}
          <span
            className={`p-2 rounded text-sm font-semibold text-black ${statusInfo.bgColor}`}
          >
            {statusInfo.text} ({selectedQuote.status})
          </span>
        </p>
      </div>

      <div className="mt- flex flex-inline items-center gap-2">
        <span className="font-medium">Fecha de Creacion:</span> 
        <p>
          
            {selectedQuote.creation_date
              ? format(
                  new Date(selectedQuote.creation_date),
                  "yyyy-MM-dd  HH:mm:ss"
                )
              : "No especificado"}
        
        </p>
      </div>
    </div>
  );
}
