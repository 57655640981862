import React from "react";
import { useParams } from "react-router-dom";
import CopyLinkButton from "../../../../../utils/tableButtons/CopyLinkButton";
import ToggleButton from "../../../../../utils/tableButtons/ToggleButton";
import SubMenuButton from "./tableButtons/SubMenuButton";

export default function TableActionsPackages({
  pkg,
  isTourVisible,
  setIsTourVisible,
}) {
  const {tour_op_id} = useParams();

  return (
    <div className="flex flex-row ">
       <CopyLinkButton
        route={"package-link"}
        tour_op_id={tour_op_id}
        data={pkg}
      /> 

      <ToggleButton
        toggleVisibility={setIsTourVisible}
        isVisible={isTourVisible}
      />

      <SubMenuButton tour_op_id={tour_op_id} pkg={pkg} />
    </div>
  );
}
